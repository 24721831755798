@use "../abstracts" as *;
@use "../components/splide";
@use "../components/team-box";
@use "../components/publication";
@use "../components/banner-pages";

.banner {
    @include for-desktop-up {
        margin-bottom: 20px;
    }

    @include for-phone-only {
        margin-bottom: 20px;
    }

    &-splide {
        li {
            @include flex(flex, column, center, center);

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &__item {
            @include flex(flex, column, center, center);

            &::after {
                content: "";
                display: block;
                width: 100%;
                height: 5px;
                background: linear-gradient(
                    to right,
                    #f1cb91 0% 25%,
                    #4badb0 25% 50%,
                    #8797b8 50% 75%,
                    #d3696f 75% 100%
                );
            }
        }

        .container {
            position: absolute;
            bottom: 20%;
            left: 50%;
            width: 100%;

            @include for-tablet-portrait-only {
                bottom: 45%;
                left: 10%;
            }

            @include for-phone-only {
                bottom: 30%;
                right: 0;
                left: unset;
            }
        }

        &__description {
            font-family: "Roboto", sans-serif;

            h1,
            h2 {
                color: color(secondary, 300);
                font-weight: 900;
                font-size: size(title, 500);
                max-width: 20ch;

                @include for-tablet-portrait-only {
                    font-size: 24px;
                }

                @include for-phone-only {
                    font-size: size(text, 400);
                }
            }

            @include for-phone-only {
                h1 {
                    text-align: start;
                    font-size: size(title, 100);
                }
            }

            p {
                font-size: size(title, 200);
                max-width: 33ch;
                color: color(secondary, 300);
                line-height: 1.3;

                @include for-tablet-portrait-only {
                    font-size: 14px;
                    max-width: 40ch;
                }

                @include for-phone-only {
                    // font-size: size(paragraph, 100);
                    // max-width: initial;
                    display: none;
                }
            }

            a {
                padding: 12px 30px;
                color: color(white, 100);
                border: none;
                text-transform: uppercase;
                font-size: 14px;
                font-weight: 700;
                display: inline-block;
                margin-top: 24px;
                transition: all 0.7s;
                background-color: color(secondary, 300);

                &:hover {
                    background-color: color(secondary, 200);
                }

                @include for-phone-only {
                    display: block;
                    // margin: 24px auto;
                    width: max-content;
                    font-size: 10px;
                }
            }
        }
    }

    .splide {
        &__arrow {
            &--prev {
                left: 10em;
            }

            &--next {
                right: 10em;
            }
        }
    }

    @include for-tablet-portrait-only {
        .splide__arrow {
            top: 60%;
        }
    }
}

.treatment {
    &__list {
        margin-top: 50px;

        @include grid(grid, repeat(4, 1fr), auto, 47px);

        @include for-tablet-portrait-only {
            @include grid(grid, repeat(2, 1fr), auto, 47px);
        }

        @include for-phone-only {
            @include grid(grid, 1fr, auto, 47px);
        }

        &__item {
            padding: 10px;
            border-radius: 20px;

            &__title {
                font-size: size(text, 500);
                text-align: center;
                font-weight: 900;
                margin-bottom: 12px;
            }

            p {
                text-align: center;
                font-size: size(text, 100);
                color: #52575d;
                margin-bottom: 0;
            }
        }

        &__item:first-child {
            border: 2px solid #f3d29f;
            color: #f3d29f;
        }

        &__item:nth-child(2) {
            border: 2px solid #4badb0;
            color: #4badb0;
        }

        &__item:nth-child(3) {
            border: 2px solid #9b8cb7;
            color: #9b8cb7;
        }

        &__item:last-child {
            border: 2px solid #d3696f;
            color: #d3696f;
        }
    }
}

.covenant {
    .splide {
        &__arrow {
            opacity: 1;
        }
    }
}

.team {
    background-color: rgba(196, 213, 232, 19%);
    &__content {
        &__title {
            font-size: size(title, 200);
            color: color(secondary, 300);
            font-weight: 900;
        }
        @include for-phone-only {
            &__title {
                font-size: size(title, 100);
            }
            .btn-tertiary {
                font-size: size(text, 200);
            }
        }
    }
}

.news {
    .btn-section-title {
        margin-bottom: 50px;
    }
    &__rightcontent {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        gap: 1rem;

        a {
            border: 1px solid color(secondary, 300);
            text-decoration: none;
            padding: 14px;
            border-radius: 10px;

            p {
                font-size: size(text, 300);
                font-weight: 600;
            }

            img {
                max-width: 116px;
                border-radius: 10px;
            }
        }
    }
}

.publication__item__figure img:first-child,
.publication__item__figure img:nth-child(2) {
    border-radius: 20px 0 20px 0 !important;
}
.banner__pages {
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
.banner__pages__title {
    color: color(secondary, 300);
    text-align: center;
    &::after {
        display: none;
    }
}
.banner__pages__subtitle {
    background-color: color(secondary, 300);
    padding: 10px 35px;
    border-radius: 10px;
    width: 510px;
    text-align: center;

    @include for-phone-only {
        width: initial;
        font-size: .9rem;
    }
}
.banner__pages__secondsubtitle {
    font-size: size(title, 100);
    color: color(white, 100);
    background-color: color(secondary, 300);
    padding: 10px 35px;
    border-radius: 10px;
    width: 510px;
    text-align: center;

    @include for-phone-only {
        font-size: .9rem;
        width: initial;
    }
}
