@use "../abstracts" as *;

.banner__pages {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 193px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;


  &__title {
    font-size: size(title, 300);
    color: color(white, 100);
    font-weight: 900;
    margin-bottom: 14px;

    @include for-phone-only {
      font-size: size(title, 200);
    }

    &::after {
      content: "";
      display: block;
      height: 2px;
      max-width: 600px;
      background-color: color(white, 100);
      margin-top: 5px;
      border-radius: 4px;
    }
  }

  &__subtitle {
    color: color(white, 100);
    font-size: size(title, 100);
    margin-bottom: 1.4rem;

    @include for-phone-only {
        font-size: size(text, 400);
    }
  }

  &__link {
    color: color(secondary, 300);
    background-color: color(secondary, 100);
    padding: 14px 70px;
    border-radius: 10px;
    text-decoration: none;
    font-size: size(text, 500);
    font-weight: 600;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    &:hover {
        color: color(secondary, 200);
    }

    @include for-phone-only {
        font-size: size(text, 300);
        padding: 14px 40px;
    }
  }

  .breadcrumb {

    &-item {
      color: color(white, 100);
      font-size: size(text, 400);

      &__link {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
            color: color(white, 100);
        }
      }

      &::before {
        color: color(white, 100);
      }

      @include for-phone-only {
        font-size: size(text, 200);
      }
    }
  }
}
