@use "../abstracts/" as *;

.team {
    &__content {
        &__title {
            font-size: size(title, 200);
            color: color(secondary, 300);
            font-weight: 900;
            margin-bottom: 2rem;
        }
    }
}
