@use "../abstracts" as *;

.publication__item {
    background-color: color(white, 100);
    border-radius: 15px;
    justify-content: space-between;

    &__article {
        @include grid(grid, auto, auto 48px 72px, 1rem);
        &__date {
            color: color(grayScale, 700);
            font-size: size(text, 200);
        }

        &__category {
            color: color(secondary, 200);
        }

        &__title {
            color: color(secondary, 300);
            margin-bottom: 1rem;
            font-size: size(title, 100);
        }

        &__description {
            p {
                color: #595959;
            }
        }

        // &__Link {
        //     padding: 8px 30px !important;
        // }

    }

    &__figure {
        max-height: 300px;

        img {
            border-radius: 20px;
        }
    }

    &__image {
        width: 100%;
        height: 100%;
        max-height: 300px;
        object-fit: cover;
    }

    &-gallery {
        background-color: color(grayScale, 400, 0.22);

        &__title {
            font-size: size(title, 200);

            &::after {
                margin-right: unset;
                margin-left: unset;
            }
        }
    }

    @include for-tablet-portrait-up {
        &__item {
            @include grid(grid, 240px auto, auto, 1rem);
        }

        &__figure {
            margin-bottom: unset;
        }

        &__image {
            width: 175px;
            height: 100%;
        }
    }
}
